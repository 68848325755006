<template>
  <div>
      <a-space class="top-content" size="large">
          <span class="back-btn" @click='back'><a-icon type="left" style='margin-right:10px' />返回</span>
          <span>订单号：{{infoData.code}}</span>
          <span>下单时间：{{infoData.createDate}}</span>
          <span>{{infoData.payType | payType}}</span>
      </a-space>
      <a-card class="gray-content">
          <a-row>
              <a-col :span="4">
                  <div class="tradeStatus">
                      {{infoData.tradeStatus|tradeStatus}}
                  </div>
                  <div style='margin-bottom:10px'  v-if="infoData.tradeStatus===2">买家已付款至账户，请尽快发货，否则买家有权申请退款。</div>
                  <a-space size='large'><a-button type="primary"  v-if="infoData.tradeStatus===2">确认发货</a-button><a v-if="infoData.tradeStatus===2||infoData.tradeStatus===4">取消订单</a></a-space>
              </a-col>
              <a-col :span="20" style="margin-top:20px">
                    <a-steps  :current="recordList.length-1">
                        <a-step :title="item.content" v-for="(item) in recordList" :key="item.id">
                            <template slot="description">
                                {{item.createDate}}
                            </template>
                        </a-step>
                    </a-steps>
              </a-col>
          </a-row>
      </a-card>
      <a-card class="white-content">
        <div>
            <span class="box">
                <a-descriptions title="买家信息" :column="1">
                    <a-descriptions-item label="买家">
                        {{infoData.payName}}
                    </a-descriptions-item>
                    <a-descriptions-item label="留言">
                        {{infoData.remake}}
                    </a-descriptions-item>
                </a-descriptions>
            </span>
            <span class="box">
                <a-descriptions title="收货人信息" :column="1">
                    <a-descriptions-item label="收货人">
                        {{infoData.receiverName}}
                    </a-descriptions-item>
                    <a-descriptions-item label="联系方式">
                        {{infoData.receiverTel}}
                    </a-descriptions-item>
                    <a-descriptions-item label="收货地址">
                        {{infoData.locationName+infoData.addressDetail}}
                    </a-descriptions-item>
                </a-descriptions>
            </span>
            <span class="box">
                <a-descriptions title="付款信息" :column="1">
                    <a-descriptions-item label="实付金额">
                        {{'￥'+(infoData.payPrice*1).toFixed(2)}}
                    </a-descriptions-item>
                    <a-descriptions-item label="付款方式">
                        {{infoData.payType | payType}}
                    </a-descriptions-item>
                    <a-descriptions-item label="付款时间">
                        {{infoData.createDate}}
                    </a-descriptions-item>
                </a-descriptions>
            </span>
            <span class="box">
                <a-descriptions title="配送信息" :column="1">
                    <a-descriptions-item label="配送方式">
                        快递
                    </a-descriptions-item>
                    <a-descriptions-item label="配送公司">
                        顺丰
                    </a-descriptions-item>
                    <a-descriptions-item label="配送单号">
                        76845423
                    </a-descriptions-item>
                </a-descriptions>
            </span>
        </div>
      </a-card>
      <a-table class='white-content' :columns="detailColumns" :pagination="false" :data-source='skuList' :rowKey="(record,index)=>index">
          <span slot="skuName" slot-scope="text, row">
              <img :src="row.mainPhoto" class="table-img" alt="">
              <span>{{row.skuName}}</span>
          </span>
      </a-table>
      <a-card class="gray-content right">
        <a-descriptions :column="1">
            <a-descriptions-item label="商品总价">
                {{'￥'+(infoData.payPrice - infoData.freightFee).toFixed(2)}}
            </a-descriptions-item>
            <a-descriptions-item label="运费">
                {{'￥'+(infoData.freightFee*1).toFixed(2)}}
            </a-descriptions-item>
        </a-descriptions>
        <span style='color:#000000D9;font-size:20px;line-height:28px;font-weight:500'>实付款：</span>
        <span style='color:#CF1322;font-size:20px;line-height:26px;font-weight:500;font-style: normal;'>{{'￥'+(infoData.payPrice*1).toFixed(2)}}</span>
      </a-card>
  </div>
</template>

<script>
import { orderInfo,getOrderRecord } from '@/api/shop/order'
import { detailColumns } from './config'
export default {
	data(){
		return{
			infoData:{},
			skuList:[],
            recordList: [],
			detailColumns
		}
	},
	created() {
		if(this.$route.params.id){
			this.getInfo(this.$route.params.id)
		}
	},
	filters: {
		tradeStatus: function (value) {
			switch (value) {
			// 交易状态(0.交易创建并等待买家付款,1.未付款交易超时关闭或支付完成后全额退款,
			// 2.交易支付成功（待发货）,3.交易结束并不可退款，4.出库（待收货）,5.订单完成,
			// 6.申请取消,7.申请拒收，8.取消订单失败9.取消订单成功10.发生拆单,11.售后换新)
			case 0:
				return '交易创建并等待买家付款'
			case 1:
				return '交易关闭'
			case 2:
				return '待发货'
			case 3:
				return '交易结束'
			case 4:
				return '待收货'
			case 5:
				return '订单完成'
			case 6:
				return '申请取消'
			case 7:
				return '申请拒收'
			case 8:
				return '取消订单失败'
			case 9:
				return '取消订单成功'
			case 10:
				return '发生拆单'
			case 11:
				return '售后换新'
			default:
				break;
			}
		},
		payType: function (value) {
			switch (value) {
			// 1.支付宝，2.微信，3.现金，4.pos，5.预缴扣除，6.银行卡，7.支票，8.转账，9.开发商代付）
			case 1:
				return '支付宝'
			case 2:
				return '微信'
			case 3:
				return '现金'
			case 4:
				return 'pos'
			case 5:
				return '预缴扣除'
			case 6:
				return '银行卡'
			case 7:
				return '支票'
			case 8:
				return '转账'
			case 9:
				return '开发商代付'
			default:
				break;
			}
		}
	},
	methods:{
		getInfo(id){
			orderInfo({orderId:id}).then(res=>{
				this.infoData = res.data
				this.skuList = res.data.skuList
			})
            getOrderRecord({orderId:id}).then(res => {
                let data = res.data
                this.recordList = data
            })
		},
		back(){
			this.$router.go(-1)
		}
	}
}
</script>

<style lang="less" scoped>
.top-content{
    padding-left:13px ;
    padding-bottom:20px ;
    padding-top: 5px;
    font-size: 14px;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
}
.gray-content{
    width:95%;
    background:#00000005;
    margin:auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    .tradeStatus{
        color: #000000D9;
        font-weight: 500;
        font-size: 20px;
    }
}
.white-content{
    width:95%;
    margin:auto;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 8px;
}
.box{
    width: 25%;
    display: inline-block;
    margin: auto;
    vertical-align:top;
    padding-left: 20px;
    padding-right: 20px;
}
.table-img{
    width: 40px;
    height: 40px;
    display: inline;
}
.right{
    text-align: right;
    padding-right: 20px;
}
</style>